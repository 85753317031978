import { ApplicationState } from "../types";

const _getData = (state: ApplicationState) => state.PlannerData;

const getPlannerModels = (state: ApplicationState) =>
  _getData(state).plannerModels;

const getAddedPlannerModel = (state: ApplicationState) =>
  _getData(state).addedPlannerModel;

const getRemovedPlannerModel = (state: ApplicationState) =>
  _getData(state).removedPlannerModel;

const getSelectedPlannerModel = (state: ApplicationState) =>
  _getData(state).selectedPlannerModel;

const getCurrentAction = (state: ApplicationState) =>
  _getData(state).currentAction;

const selectors = {
  getPlannerModels,
  getAddedPlannerModel,
  getRemovedPlannerModel,
  getSelectedPlannerModel,
  getCurrentAction,
};
export default selectors;
