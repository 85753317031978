import { TilesetData, TilesetEditorParams  } from "../../@types";

export enum TilesetDataActionTypes {
  FETCH_TILESET_DATA = "TilesetData@FETCH_TILESET_DATA",
  SET_TILESET_DATA = "TilesetData@SET_TILESET_DATA",
  SET_LOADING = "TilesetData@SET_LOADING",
  SET_LOADING_SAVE_NEW_TILESET_EDITOR_PARAMS = "TilesetData@SET_LOADING_SAVE_NEW_TILESET_EDITOR_PARAMS",
  SET_ERROR = "TilesetData@SET_ERROR",
  SAVE_NEW_TILESET_EDITOR_PARAMS = "TilesetData@SAVE_NEW_TILESET_EDITOR_PARAMS",
  CLEAR = "TilesetData@CLEAR",
}

export type TilesetDataState = {
  tilesetData?: TilesetData;
  loading: boolean;
  savingNewTilesetEditorParams: boolean;
  error: undefined | Error;
};

export type TilesetDataPayload = {
  tilesetData?: TilesetData;
  tilesetEditorParams?: TilesetEditorParams;
  loading?: boolean;
  savingNewTilesetEditorParams?: boolean;
  assessmentId?: string | null;
  error?: Error;
};

export type TilesetDataAction = {
  payload?: TilesetDataPayload;
  type: TilesetDataActionTypes;
};

export type TilesetDataReducer = (
  state: TilesetDataState,
  payload?: TilesetDataPayload
) => TilesetDataState;
