import { TilesetData } from "../../@types";
import { mountTilesetURL } from "../../utils";
import * as api from "./api";
import { TilesetDataRequestResponse } from "./types";

const parseTilesetRequestResponseToTileset = (
  data: TilesetDataRequestResponse
): TilesetData => ({
  id: data.Id,
  siteAssessmentId: data.SiteAssessmentId,
  storageFolder: data.StorageFolder,
  s3Link: data.S3Link,
  latitude: data.Lat,
  siteLatitude: data.SiteLatitude,
  siteLongitude: data.SiteLongitude,
  longitude: data.Longitude,
  tilesetHeight: data.TilesetHeight,
  tilesetHeading: data.TilesetHeading,
  tilesetPitch: data.TilesetPitch,
  tilesetRoll: data.TilesetRoll,
  tilesetX: data.TilesetX,
  tilesetY: data.TilesetY,
  tilesetZ: data.TilesetZ,
  tilesetScale: data.TilesetScale,
  tilesetGroundPointHeight: data.TilesetGroundPointHeight,
  storage: data.Storage,
  appendix: data.Appendix,
  edit: data.Edit,
  max: data.Max,
  min: data.Min,
  siteId: data.SiteId,
  storyUrl: data.StoryUrl,
  tilesetUrl:
    data.TilesetUrl ?? mountTilesetURL(data.Storage, data.StorageFolder),
  modelCenterAtOrigin: !!data.ModelCenterAtOrigin,
  modelGeoReferenced: !!data.ModelGeoReferenced,
});

const parseTilesetResponse = (
  data: any
): any => ({
  id: data.id,
  siteAssessmentId: data.site_assessment_staging_id,
  storageFolder: data.storage_folder,
  s3Link: data.s3_link,
  latitude: data.latitude,
  longitude: data.longitude,
  siteLatitude: data.latitude,
  siteLongitude: data.longitude,
  tilesetHeight: data.height,
  tilesetHeading: data.heading,
  tilesetPitch: data.pitch,
  tilesetRoll: data.roll,
  tilesetX: data.x,
  tilesetY: data.y,
  tilesetZ: data.z,
  tilesetScale: data.scale,
  tilesetGroundPointHeight: data.ground_point_height,
  storage: data.storage,
  appendix: data.appendix,
  edit: data.edit,
  max: data.max,
  min: data.min,
  siteId: data.site_id,
  storyUrl: data.story_url,
  tilesetUrl: data.tileset_url,
  modelCenterAtOrigin: !!data.model_center_at_origin,
  modelGeoReferenced: !!data.model_geo_referenced,
});

const parseTilesetToTilesetRequestResponse = (
  data: Partial<TilesetData>,
  misc: any
): any => ({
  id: data.id,
  site_assessment_id: data.siteAssessmentId,
  s3_link: data.s3Link,
  latitude: data.latitude,
  longitude: data.longitude,
  height: data.tilesetHeight,
  heading: data.tilesetHeading,
  pitch: data.tilesetPitch,
  roll: data.tilesetRoll,
  x: data.tilesetX,
  y: data.tilesetY,
  z: data.tilesetZ,
  scale: data.tilesetScale,
  ground_point_height: data.tilesetGroundPointHeight,
  storage: misc.storage,
  storage_folder: misc.storageFolder,
  appendix: data.appendix,
  edit: data.edit,
  max: data.max,
  min: data.min,
  site_id: data.siteId,
  story_url: misc.storyUrl,
  tileset_url: misc.tilesetUrl,
  model_center_at_origin: !!data.modelCenterAtOrigin,
  model_geo_referenced: !!data.modelGeoReferenced,
});

export const fetchTilesetData = async (
  assessmentId: string
): Promise<TilesetData> => {
  try {
    const result = await api.fetchTileset(
      assessmentId
    );
    return parseTilesetResponse(result.tileset_settings);
  } catch (e: any) {
    throw e;
  }
};

export const saveTilesetEditorParams = async (
  tileset: TilesetData,
  tilesetMisc: any
): Promise<TilesetData> => {
  try {
    const tilesetData = parseTilesetToTilesetRequestResponse(tileset, tilesetMisc);
    const result: TilesetDataRequestResponse =
      await api.saveTilesetEditorParams(tileset.siteAssessmentId, { tileset_settings: tilesetData });

    return parseTilesetRequestResponseToTileset(result);
  } catch (e: any) {
    throw e;
  }
};
