export const MAX_CLIENTHEIGHT = 505;
export const MIN_HEIGHT = 475;

export const SIDEBAR_HEIGHT = 693;
export const TEXTFIELD_WIDTH = 125;

export const SLIDER_TOP = 450;
export const SLIDER_WIDTH = 400;

export const COMPASS_TOP = 45;
export const COMAPSS_RIGHT = 20;

export const COMPASS_RESET_BTN_TOP = 126;
export const COMPASS_RESET_BTN_RIGHT = 101;