import { Viewer as CesiumViewer } from "cesium";

import { useEffect, useRef } from "react";

import { PlannerActions } from "../../../@types";

import { TilesetViewer } from "../../../components/TilesetViewer";
import { usePlannerTransformations } from "../../../hooks/usePlannerTransformations";
import { useSitePlanner } from "../../../hooks/useSitePlanner";
import { useTileset } from "../../../hooks/useTileset";

export const TilesetBuilder = () => {
  const cesiumViewerRef = useRef<CesiumViewer>();

  const { addedPlannerModel, removedPlannerModel, currentAction } =
    useSitePlanner();
  const { initialTilesetSettings } = useTileset();

  const {
    newPlannerModel,
    newPlannerPrimitive,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
    removePlannerModel,
  } = usePlannerTransformations(cesiumViewerRef.current);

  const getCesiumViewerRef = (viewerRef: CesiumViewer) => {
    cesiumViewerRef.current = viewerRef;
  };

  useEffect(() => {
    if (addedPlannerModel && addedPlannerModel.ionId) {
      newPlannerModel(addedPlannerModel);
    } else if (addedPlannerModel && addedPlannerModel.shape) {
      newPlannerPrimitive(addedPlannerModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedPlannerModel]);

  useEffect(() => {
    if (removedPlannerModel) {
      removePlannerModel(removedPlannerModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedPlannerModel]);

  useEffect(() => {
    disableCurrentEditor();
    if (currentAction) {
      if (currentAction === PlannerActions.ZOOM) {
        zoomToSelectedPlannerModel();
      }
      if (currentAction === PlannerActions.EDIT) {
        editSelectedPlannerModel();
      }
    }
  }, [
    currentAction,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
  ]);

  return (
    <TilesetViewer
      tilesetSettings={initialTilesetSettings}
      getCesiumViewerRef={getCesiumViewerRef}
    />
  );
};
