import { ModelListProps } from "./types";
import { modelOptions } from "./constants";
import { ModelOption, ModelOptionGroup } from "../../../@types";

import {
  ListContainer,
  ModelOptionContainer,
  ModelOptionGroupContainer,
  Container,
  DropDownButton,
  ListContent,
  DropDownContainer,
} from "./styles";
import { useCallback, useEffect, useState } from "react";
import { Icon } from "../../../components/Icon";

export function ModelList({ onModelSelect, selectedModel }: ModelListProps) {
  const [showModelList, setShowModelList] = useState(false);
  const [selectedModelOptionLabel, setSelectedModelOptionLabel] =
    useState("Select Model");

  const onModelOptionClick = useCallback(
    (option: ModelOption) => {
      onModelSelect(option);
      setShowModelList(false);
    },
    [onModelSelect]
  );

  useEffect(() => {
    if (selectedModel) {
      setSelectedModelOptionLabel(selectedModel.label);
    } else {
      setSelectedModelOptionLabel("Select Model");
    }
  }, [selectedModel]);

  const renderOption = (option: ModelOption) => {
    return (
      <ModelOptionContainer
        key={option.ionId}
        onClick={() => onModelOptionClick(option)}
      >
        {option.label}
      </ModelOptionContainer>
    );
  };
  const renderOptionsGroup = (optionsGroup: ModelOptionGroup) => {
    return (
      <ModelOptionGroupContainer key={optionsGroup.label}>
        <span style={{ paddingLeft: 12 }}>{optionsGroup.label}</span>
        {optionsGroup.models.map((option) => renderOption(option))}
      </ModelOptionGroupContainer>
    );
  };
  return (
    <Container>
      <DropDownContainer>
        <DropDownButton
          onClick={() => setShowModelList(!showModelList)}
          style={
            showModelList
              ? { borderBottom: "1px solid rgba(36, 56, 82, 0.08)" }
              : {}
          }
        >
          <span style={{ marginTop: 2 }}>{selectedModelOptionLabel}</span>
          <Icon iconSrc="expand_more" inverted={showModelList} />
        </DropDownButton>
        <ListContainer style={showModelList ? { height: "60vh" } : {}}>
          <ListContent>
            {modelOptions.map((group) => renderOptionsGroup(group))}
          </ListContent>
        </ListContainer>
      </DropDownContainer>
    </Container>
  );
}
