import { PlannerActions, PlannerModel } from "../../@types";

export enum PlannerDataActionTypes {
  ADD_PLANNER_MODEL = "PlannerData@ADD_PLANNER_MODEL",
  CLEAR_ADDED_PLANNER_MODEL = "PlannerData@CLEAR_ADDED_PLANNER_MODEL",
  CLEAR_REMOVED_PLANNER_MODEL = "PlannerData@CLEAR_REMOVED_PLANNER_MODEL",
  SET_SELECTED_PLANNER_MODEL = "PlannerData@SET_SELECTED_PLANNER_MODEL",
  REMOVE_PLANNER_MODEL = "PlannerData@REMOVE_PLANNER_MODEL",
  ZOOM_TO_PLANNER_MODEL = "PlannerData@ZOOM_TO_PLANNER_MODEL",
  EDIT_PLANNER_MODEL = "PlannerData@EDIT_PLANNER_MODEL",
  CLEAR = "PlannerData@CLEAR",
}

export type PlannerDataState = {
  plannerModels: PlannerModel[];
  addedPlannerModel?: PlannerModel;
  removedPlannerModel?: PlannerModel;
  selectedPlannerModel?: PlannerModel;
  currentAction?: PlannerActions;
};

export type PlannerDataPayload = {
  addedPlannerModel?: PlannerModel;
  removedPlannerModel?: PlannerModel;
  selectedPlannerModel?: PlannerModel;
  currentAction?: PlannerActions;
};

export type PlannerDataAction = {
  payload?: PlannerDataPayload;
  type: PlannerDataActionTypes;
};

export type PlannerDataReducer = (
  state: PlannerDataState,
  payload?: PlannerDataPayload
) => PlannerDataState;
