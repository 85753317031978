import { all } from "redux-saga/effects";
import tilesetDataSagas from "./TilesetData/sagas";
import siteSaga from './Site/sagas'

function* rootSagas() {
  yield all([...tilesetDataSagas]);
  yield all([...siteSaga]);
}

export default rootSagas;
