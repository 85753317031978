import { PlannerActions, PlannerModel } from "../../@types";
import { PlannerDataAction, PlannerDataActionTypes } from "./types";

const actions = {
  addPlannerModel: (addedPlannerModel: PlannerModel): PlannerDataAction => ({
    payload: { addedPlannerModel },
    type: PlannerDataActionTypes.ADD_PLANNER_MODEL,
  }),
  clearAddedPlannerModel: () => ({
    type: PlannerDataActionTypes.CLEAR_ADDED_PLANNER_MODEL,
  }),
  clearRemovedPlannerModel: () => ({
    type: PlannerDataActionTypes.CLEAR_REMOVED_PLANNER_MODEL,
  }),
  setSelectedPlannerModel: (selectedPlannerModel: PlannerModel) => ({
    payload: { selectedPlannerModel },
    type: PlannerDataActionTypes.SET_SELECTED_PLANNER_MODEL,
  }),
  zoomToPlannerModel: (plannerModel: PlannerModel) => ({
    payload: {
      selectedPlannerModel: plannerModel,
      currentAction: PlannerActions.ZOOM,
    },
    type: PlannerDataActionTypes.ZOOM_TO_PLANNER_MODEL,
  }),
  editPlannerModel: (plannerModel: PlannerModel) => ({
    payload: {
      selectedPlannerModel: plannerModel,
      currentAction: PlannerActions.EDIT,
    },
    type: PlannerDataActionTypes.EDIT_PLANNER_MODEL,
  }),
  clearSelectedPlannerModel: () => ({
    payload: { selectedPlannerModel: undefined },
    type: PlannerDataActionTypes.SET_SELECTED_PLANNER_MODEL,
  }),
  removePlannerModel: (
    removedPlannerModel: PlannerModel
  ): PlannerDataAction => ({
    payload: { removedPlannerModel, currentAction: PlannerActions.DELETE },
    type: PlannerDataActionTypes.REMOVE_PLANNER_MODEL,
  }),
  clear: (): PlannerDataAction => ({
    type: PlannerDataActionTypes.CLEAR,
  }),
};

export default actions;
