import { SliderInput, SliderContainer } from "./styles";
import { SliderProps } from "./types";

export const Slider = ({
  min,
  max,
  value,
  step,
  style,
  onValueChange,
}: SliderProps) => {
  return (
    <SliderContainer>
      <SliderInput
        style={ style}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          onValueChange(Number(e.currentTarget.value))
        }
      />

    </SliderContainer>
  );
};
