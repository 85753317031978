import { Cartesian3 } from "cesium";
import { ApplicationState } from "../types";

const _getData = (state: ApplicationState) => state.TilesetData;

const getTilesetData = (state: ApplicationState) => _getData(state).tilesetData;

const isLoading = (state: ApplicationState) => _getData(state).loading;
const getError = (state: ApplicationState) => _getData(state).error;
const getTilesetUrl = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetUrl;
const getTilesetOffsetCoordinates = (state: ApplicationState) => {
  const tileseData = getTilesetData(state);
  const offsetPosition = new Cartesian3();
  if (tileseData) {
    offsetPosition.x = tileseData.tilesetX;
    offsetPosition.y = tileseData.tilesetY;
    offsetPosition.z = tileseData.tilesetZ;
  }
  return offsetPosition;
};
const getTilesetScale = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetScale;

const getTilesetHeading = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetHeading;

const getTilesetRoll = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetRoll;

const getTilesetPitch = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetPitch;

const getTilesetHeight = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetHeight;

const getTilesetGroundPointHeight = (state: ApplicationState) =>
  getTilesetData(state)?.tilesetGroundPointHeight;

const getLatitude = (state: ApplicationState) =>
  getTilesetData(state)?.latitude;

const getLongitude = (state: ApplicationState) =>
  getTilesetData(state)?.longitude;

const getOriginLatitude = (state: ApplicationState) =>
    getTilesetData(state)?.siteLatitude;

const getOriginLongitude = (state: ApplicationState) =>
    getTilesetData(state)?.siteLongitude;

const hasTilesetSettings = (state: ApplicationState) =>
  !!getTilesetData(state)?.latitude;

const getModelCenterAtOrigin = (state: ApplicationState) =>
  getTilesetData(state)?.modelCenterAtOrigin;

const getModelGeoReferenced = (state: ApplicationState) =>
  getTilesetData(state)?.modelGeoReferenced;

const getSiteAssessmentId = (state: ApplicationState) =>
  getTilesetData(state)?.siteAssessmentId;

const isSavingNewTilesetEditorParams = (state: ApplicationState) =>
  _getData(state)?.savingNewTilesetEditorParams;

const selectors = {
  getTilesetData,
  isLoading,
  isSavingNewTilesetEditorParams,
  getError,
  getTilesetUrl,
  getTilesetOffsetCoordinates,
  hasTilesetSettings,
  getModelCenterAtOrigin,
  getModelGeoReferenced,
  getTilesetScale,
  getTilesetHeading,
  getTilesetRoll,
  getTilesetPitch,
  getTilesetHeight,
  getTilesetGroundPointHeight,
  getLatitude,
  getLongitude,
  getOriginLatitude,
  getOriginLongitude,
  getSiteAssessmentId,
};
export default selectors;
