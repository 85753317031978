import {
  TilesetDataAction,
  TilesetDataActionTypes,
  TilesetDataReducer,
  TilesetDataPayload,
  TilesetDataState,
} from "./types";

export const initialState: TilesetDataState = {
  tilesetData: undefined,
  loading: false,
  savingNewTilesetEditorParams: false,
  error: undefined,
};

const setError: TilesetDataReducer = (
  state: TilesetDataState,
  payload?: TilesetDataPayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: TilesetDataReducer = (
  state: TilesetDataState,
  payload?: TilesetDataPayload
) => ({
  ...state,
  loading: !!payload?.loading,
});

const setLoadingSaveNewTilesetEditorParams: TilesetDataReducer = (
  state: TilesetDataState,
  payload?: TilesetDataPayload
) => ({
  ...state,
  savingNewTilesetEditorParams: !!payload?.savingNewTilesetEditorParams,
});

const setTilesetData: TilesetDataReducer = (
  state: TilesetDataState,
  payload?: TilesetDataPayload
) => ({
  ...state,
  tilesetData: (payload && payload.tilesetData) ?? state.tilesetData,
});

const clearState: TilesetDataReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<TilesetDataActionTypes, TilesetDataReducer>([
  [TilesetDataActionTypes.SET_TILESET_DATA, setTilesetData],
  [TilesetDataActionTypes.SET_LOADING, setLoading],
  [
    TilesetDataActionTypes.SET_LOADING_SAVE_NEW_TILESET_EDITOR_PARAMS,
    setLoadingSaveNewTilesetEditorParams,
  ],
  [TilesetDataActionTypes.SET_ERROR, setError],
  [TilesetDataActionTypes.CLEAR, clearState],
]);

const reducer = (
  state: TilesetDataState = initialState,
  action: TilesetDataAction
): TilesetDataState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
