import { Route, Routes } from "react-router-dom";
import { TilesetViewer } from "../Pages/TilesetViewer";
import { TilesetBuilder } from "../Pages/TilesetBuilder";
import routes from "./routes";
import { TilesetEditor } from "../Pages/TilesetEditor";

export function Router() {
  return (
    <Routes>
      <Route path={routes.viewer} element={<TilesetViewer />} />
      <Route path={routes.sitePlanner} element={<TilesetBuilder />} />
      <Route path={routes.editor} element={<TilesetEditor />} />
    </Routes>
  );
}
