import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TilesetBuilder as Builder } from "../../section/@tileset/Builder";
import LoadSpinner from "../../components/LoadSpinner";
import { useTileset } from "../../hooks/useTileset";
import { Container, PlannerModelContainer } from "./styles";

import { AddModel } from "../../section/@tileset/AddModel";
import { PlannerActions } from "../../@types";
import { useSitePlanner } from "../../hooks/useSitePlanner";
import { ModelCard } from "../../section/@tileset/ModelCard";

export const TilesetBuilder = () => {
  const { isLoading, error, tilesetData, fetchTilesetData } = useTileset();

  const {
    plannerModels,
    selectedPlannerModel,
    currentAction,
    addedPlannerModel,
    clearSelectedPlannerModel,
    addPlannerModel,
    zoomToPlannerModel,
    editPlannerModel,
    removePlannerModel,
  } = useSitePlanner();
  const { assessmentId } = useParams();

  const getTileset = useCallback(() => {
    if (!assessmentId) return;
    fetchTilesetData(assessmentId);
  }, [assessmentId, fetchTilesetData]);

  useEffect(() => {
    getTileset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  if (isLoading) {
    return <LoadSpinner />;
  }
  const isEditingPlannerModel = (plannerModelId: string) => {
    if (!selectedPlannerModel) return;
    return (
      plannerModelId === selectedPlannerModel.id &&
      currentAction === PlannerActions.EDIT
    );
  };

  return (
    <>
      {tilesetData && <Builder />}
      <Container>
        <AddModel
          disablePlaceModelButton={!!addedPlannerModel}
          onAddModel={addPlannerModel}
        />
        <PlannerModelContainer>
          {plannerModels.map((plannerModel) => (
            <ModelCard
              model={plannerModel}
              key={plannerModel.id}
              onZoomClick={zoomToPlannerModel}
              onEditClick={editPlannerModel}
              onFinishEditClick={clearSelectedPlannerModel}
              onDeleteClick={removePlannerModel}
              editing={isEditingPlannerModel(plannerModel.id)}
            />
          ))}
        </PlannerModelContainer>
      </Container>
    </>
  );
};
