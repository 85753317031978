import { PlannerActions, PlannerModel } from "../../@types";
import {
  PlannerDataAction,
  PlannerDataActionTypes,
  PlannerDataReducer,
  PlannerDataPayload,
  PlannerDataState,
} from "./types";

export const initialState: PlannerDataState = {
  plannerModels: [],
  addedPlannerModel: undefined,
  removedPlannerModel: undefined,
};

const validatePlannerModel = (
  plannerModels: PlannerModel[],
  newPlannerModel: PlannerModel
) => {
  return !plannerModels.find(({ id }) => id === newPlannerModel.id);
};

const addPlannerModel: PlannerDataReducer = (
  state: PlannerDataState,
  payload?: PlannerDataPayload
) => ({
  ...state,
  addedPlannerModel: payload?.addedPlannerModel,
  selectedPlannerModel: payload?.addedPlannerModel,
  plannerModels:
    payload &&
    payload.addedPlannerModel &&
    validatePlannerModel(state.plannerModels, payload.addedPlannerModel)
      ? [payload.addedPlannerModel, ...state.plannerModels]
      : state.plannerModels,
});
const removePlannerModel: PlannerDataReducer = (
  state: PlannerDataState,
  payload?: PlannerDataPayload
) => ({
  ...state,
  removedPlannerModel: payload?.removedPlannerModel,
  currentAction: PlannerActions.DELETE,
  plannerModels:
    payload && payload.removedPlannerModel
      ? state.plannerModels.filter(
          ({ id }) => id !== payload.removedPlannerModel!.id
        )
      : state.plannerModels,
});
const clearAddedPlannerModel: PlannerDataReducer = (
  state: PlannerDataState
) => ({
  ...state,
  addedPlannerModel: undefined,
});

const clearRemovedPlannerModel: PlannerDataReducer = (
  state: PlannerDataState
) => ({
  ...state,
  removedPlannerModel: undefined,
});

const setSelectedPlannerModel: PlannerDataReducer = (
  state: PlannerDataState,
  payload?: PlannerDataPayload
) => ({
  ...state,
  selectedPlannerModel: payload?.selectedPlannerModel,
  currentAction: payload?.currentAction,
});

const clearState: PlannerDataReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<PlannerDataActionTypes, PlannerDataReducer>([
  [PlannerDataActionTypes.ADD_PLANNER_MODEL, addPlannerModel],
  [PlannerDataActionTypes.REMOVE_PLANNER_MODEL, removePlannerModel],
  [PlannerDataActionTypes.CLEAR_ADDED_PLANNER_MODEL, clearAddedPlannerModel],
  [PlannerDataActionTypes.SET_SELECTED_PLANNER_MODEL, setSelectedPlannerModel],
  [PlannerDataActionTypes.ZOOM_TO_PLANNER_MODEL, setSelectedPlannerModel],
  [PlannerDataActionTypes.EDIT_PLANNER_MODEL, setSelectedPlannerModel],
  [
    PlannerDataActionTypes.CLEAR_REMOVED_PLANNER_MODEL,
    clearRemovedPlannerModel,
  ],
  [PlannerDataActionTypes.CLEAR, clearState],
]);

const reducer = (
  state: PlannerDataState = initialState,
  action: PlannerDataAction
): PlannerDataState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
