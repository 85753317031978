import thirdPartyCookiesImg from "./3rdpartycookies.jpg"
import { Box, Typography } from 'ca-react-component-lib';

const ErrorLocalStorage = () =>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
    <Typography variant="h4">localStorage is not available.</Typography>
    <Typography variant="body1">Check if your browser is blocking third-party cookies.</Typography>
    <Typography variant="body1">This can often be the case in incognito mode</Typography>
    <img src={thirdPartyCookiesImg} alt="Third Party Cookies Incognito" style={{ width: 350 }}/>
  </Box>

export default ErrorLocalStorage;