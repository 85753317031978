
import request from '../request';
import endpoints from './endpoints';

const api = {
  fetchSite: async (assessmentId: string) =>
    request(`${endpoints.getAssessment}${assessmentId}/site/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  fetchUserProfile: async () =>
    request(`${endpoints.getUserProfile}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  fetchPhotos: async (assessmentId: string) =>
    request(`${endpoints.getAssessment}${assessmentId}/image/?file_path=drone`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  fetchCesiumLayers: async (layerSettingsId: string) =>
    request(`${endpoints.cesiumLayers}${layerSettingsId}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  saveCesiumLayers: async (assessmentId: string, payload: any) =>
    request(`${endpoints.cesiumLayers}${assessmentId}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }),
  updateCesiumLayers: async (layerSettingsId: string, payload: any) =>
    request(`${endpoints.cesiumLayers}${layerSettingsId}/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }),
};

export default api;
