import { CesiumComponentRef, Viewer } from "resium";
import {
  Viewer as CesiumViewer,
  MeasureUnits,
  DistanceUnits,
  AreaUnits,
  VolumeUnits,
  viewerMeasureMixin,
  viewerCompassMixin,
  viewerGraphicsMixin,
  viewerKeyboardCameraControlsMixin,
  viewerPlacePrimitiveMixin,
  SceneMode,
  Ion,
}
// @ts-ignore
  from "cesium";

import { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { CesiumProps } from "./types";
import { LoadingContainer } from "./styles";
import InlineLoadSpinner from "../InlineLoadSpinner";
import { useCesiumFixedCameraView, useLogger, useSite } from "../../hooks";
import authTokenModel from "../../model/AuthTokenModel";
Ion.defaultAccessToken = process.env.REACT_APP_ION_TOKEN || "";

export default function Cesium({
  isLoading,
  hideExportMeasurementButton,
  getCesiumViewerRef,
  cesiumTileset,
  onGroundPositionHeightChange,
  initialShowLayers,
}: CesiumProps) {
  const {
    enableFixedPointView,
    trackedEntity,
    onRightClick,
    onDoubleClick,
    onClick,
  } = useCesiumFixedCameraView();
  const { logToDiscord } = useLogger();

  const {
    fetchSite,
    fetchPhotos,
    loading,
    loadingImages,
    error,
    site,
    currentSiteAssessment,
    cesiumLayerSettingsId,
    fetchCesiumLayers,
    saveCesiumLayers,
    cesiumLayers,
    images,
  } = useSite();

  const { assessmentId } = useParams();

  useEffect(() => {
    if (assessmentId && authTokenModel.getAuthToken()) {
      fetchSite(assessmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId, authTokenModel.getAuthToken()]);
  
  const handleSave = useCallback((e: any) => {
      if (!assessmentId) return;
      // @ts-ignore
      return saveCesiumLayers(assessmentId, cesiumLayerSettingsId, structuredClone(e.detail))
    },
  [assessmentId, cesiumLayerSettingsId, saveCesiumLayers]);

  const handleFetchPhotos = useCallback((e: any) => {
    fetchPhotos(assessmentId);
    window.removeEventListener("fetchPhotos", handleFetchPhotos);
  }, [assessmentId, fetchPhotos]);

  useEffect(() => {
    if (assessmentId) {
      // @ts-ignore
      window.addEventListener("saveCesiumLayers", handleSave);
      window.addEventListener("fetchPhotos", handleFetchPhotos);
      return () => {
        window.removeEventListener("saveCesiumLayers", handleSave);
        window.removeEventListener("fetchPhotos", handleFetchPhotos);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId, cesiumLayerSettingsId]);

  const cesiumViewerRef = useRef<CesiumViewer>();

  useEffect(() => {
    if (cesiumViewerRef.current) cesiumViewerRef.current.scene.globe.depthTestAgainstTerrain = true;
    if (!site || !currentSiteAssessment) {
      return;
    }
    cesiumLayerSettingsId && fetchCesiumLayers(cesiumLayerSettingsId);
    const origin = '*';
    const event = {
      event: 'message',
      detail: { action: 'siteData', data: [site, currentSiteAssessment] },
    };
    if (site && currentSiteAssessment && cesiumViewerRef?.current ) {
      cesiumViewerRef.current.scene.globe.tileLoadProgressEvent.addEventListener(function (queuedTileCount:any) {

      if (cesiumViewerRef.current?.scene.globe.tilesLoaded) {
          return window.postMessage(event, origin)
        }
      });
    }
  }, [site, currentSiteAssessment, cesiumViewerRef, fetchCesiumLayers, cesiumLayerSettingsId])

  useEffect(() => {
    if (cesiumViewerRef.current && cesiumLayers) {
      // @ts-ignore
      window.dispatchEvent(new CustomEvent("syncCesiumLayersFromDB", { detail: structuredClone(cesiumLayers) }));
    }
  }, [cesiumLayers, cesiumViewerRef]);

  const getViewerRef = (viewerRef: CesiumComponentRef<CesiumViewer> | null) => {
    if (cesiumViewerRef.current) return;
    if (!viewerRef) return;
    const { cesiumElement } = viewerRef;
    cesiumViewerRef.current = cesiumElement;
  };

  const handleGroundHeightPositionChange = useCallback(
    (e: Event) => {
      const event = e as CustomEvent;
      const { value } = event.detail;
      onGroundPositionHeightChange && onGroundPositionHeightChange(value);
    },
    [onGroundPositionHeightChange]
  );

  const addMeasurement = useCallback(() => {
    // @ts-ignore
    if (!cesiumViewerRef.current || cesiumViewerRef.current.measure) return;

    document.addEventListener(
      "onGroundPositionHeightChange",
      handleGroundHeightPositionChange
    );

    const currentUnits = localStorage.getItem('measurementUnits');
    cesiumViewerRef.current.extend(viewerMeasureMixin, {
      units: new MeasureUnits({
        // @ts-ignore
        distanceUnits: currentUnits ? DistanceUnits[currentUnits] : DistanceUnits.FEET,
        areaUnits: AreaUnits.SQUARE_FEET,
        volumeUnits: VolumeUnits.CUBIC_FEET,
      }),
      hideExportMeasurementButton,
    });
  }, [handleGroundHeightPositionChange, hideExportMeasurementButton]);

  const addCompass = useCallback(() => {
    // @ts-ignore
    if (!cesiumViewerRef.current || cesiumViewerRef.current.compass) return;
    cesiumViewerRef.current.extend(viewerCompassMixin);
  }, []);

  const addFixedCameraView = useCallback(() => {
    if (!cesiumViewerRef.current) return;
    enableFixedPointView(cesiumViewerRef.current);
  }, [enableFixedPointView]);

  const addSkyBoxDestroy = useCallback(() => {
    if (!cesiumViewerRef.current) return;
    cesiumViewerRef.current.extend(viewerGraphicsMixin);
  }, []);

  useEffect(() => {
    // @ts-ignore
    cesiumViewerRef.current.extend(viewerKeyboardCameraControlsMixin);
    cesiumViewerRef.current.extend(viewerPlacePrimitiveMixin);
  }, []);

  useEffect(() => {
    if (!cesiumViewerRef.current) return;
    getCesiumViewerRef(cesiumViewerRef.current);
  }, [getCesiumViewerRef]);

  useEffect(() => {
    addFixedCameraView();
  }, [addFixedCameraView]);

  useEffect(() => {
    addCompass()
  }, [addCompass]);

  useEffect(() => {
    addSkyBoxDestroy()
  }, [addSkyBoxDestroy]);

  useEffect(() => {
      // @ts-ignore
      window.Cesium.ReactUI({
        // @ts-ignore
        viewer: cesiumViewerRef.current,
        tileset: cesiumTileset,
        assessmentId,
        initialShowLayers,
        images,
        imagesLoading: loadingImages,
      });
  }, [
    loading,
    cesiumTileset,
    assessmentId,
    initialShowLayers,
    images,
    loadingImages
  ]);

  useEffect(() => {
    addMeasurement();

    // ================ Send errors to discord ================
    // addRenderErrorsEventListener();
    // return () => {
    //   removeRenderErrorsEventListener();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onRenderError = (_: Scene, error: Error) => {
    // const fields = [
    //   { name: "Render Error", value: error.stack || error.message },
    // ];
    // const payload = [
    //   {
    //     fields,
    //   },
    // ];

    // logToDiscord(
    //   "ERROR",
    //   "An error occurred while rendering. Rendering has stopped.",
    //   payload
    // );
  // };

  // const addRenderErrorsEventListener = () => {
  //   if (cesiumViewerRef.current)
  //     cesiumViewerRef.current.scene.renderError.addEventListener(onRenderError);
  // };
  // const removeRenderErrorsEventListener = () => {
  //   if (cesiumViewerRef.current)
  //     cesiumViewerRef.current.scene.renderError.removeEventListener(
  //       onRenderError
  //     );
  // };
  // ================ Send errors to discord ================

  return error ? error : (
    <Viewer
      full
      animation={false}
      fullscreenButton={false}
      infoBox={false}
      vrButton={false}
      geocoder={false}
      baseLayerPicker={false}
      sceneMode={SceneMode.SCENE3D}
      scene3DOnly
      timeline={false}
      onDoubleClick={onDoubleClick}
      onRightClick={onRightClick}
      onClick={onClick}
      trackedEntity={trackedEntity}
      selectedEntity={trackedEntity}
      useBrowserRecommendedResolution={localStorage.getItem('disableAA') !== "true"}
      ref={(viewer) => getViewerRef(viewer)}
    >
      {(isLoading || loading) && (
        <LoadingContainer>
          <InlineLoadSpinner />
          <span style={{ paddingLeft: 6 }}>{"Loading..."}</span>
        </LoadingContainer>
      )}
    </Viewer>
  );
}
