import styled from "styled-components";

const ModelSelectContainer = styled.div`
  min-width: 410px;
  min-height: 120px;
  border-radius: 12px;
  padding: 8px;
`;

export { ModelSelectContainer };
