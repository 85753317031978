import styled from "styled-components";

const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: white;
  padding: 8px;
  text-shadow: black 2px 2px 2px, black -2px 2px 2px,
    rgba(0, 0, 0, 0.8) 2px -2px 2px, rgba(0, 0, 0, 0.8) -2px -2px 2px;
`;

export { LoadingContainer };
