import { useCallback, useState } from "react";
import { ModelOption } from "../../../@types";
import { Button } from "ca-react-component-lib";

import { Card } from "../../../components/Card";
import { ModelSelectContainer } from "./styles";
import { ModelList } from "../ModelsList";
import { PrimitiveList } from "../PrimitiveList";
import { AddModelProps } from "./types";

export function AddModel({
  disablePlaceModelButton,
  onAddModel,
}: AddModelProps) {
  const [selectedModel, setSelectedModel] = useState<ModelOption>();
  const [selectedPrimitive, setSelectedPrimitive] = useState<ModelOption>();

  const onPlaceModelButtonClick = useCallback(() => {
    if (selectedModel) {
      onAddModel(selectedModel);
      setSelectedModel(undefined);
    } else if (selectedPrimitive) {
      onAddModel(selectedPrimitive);
      setSelectedPrimitive(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel, selectedPrimitive]);


  return (
    <Card>
      <ModelSelectContainer>
        <h3 style={{ margin: 0, padding: "0 0 24px 0" }}>{"Add models"}</h3>
        <h5 style={{ margin: 0, padding: "0 0 12px 0" }}>{"Models"}</h5>
        <ModelList
          onModelSelect={setSelectedModel}
          selectedModel={selectedModel}
        />
        {/*<h5 style={{ margin: 0, padding: "12px 0 12px 0" }}>{"Primitives"}</h5>*/}
        {/*<PrimitiveList*/}
        {/*  onModelSelect={setSelectedPrimitive}*/}
        {/*  selectedModel={selectedPrimitive}*/}
        {/*/>*/}
        <div style={{ paddingTop: 24, display: "flex" }}>
          <Button
            variant={"contained"}
            disabled={disablePlaceModelButton}
            onClick={onPlaceModelButtonClick}
          >
            Place Model
          </Button>
        </div>
      </ModelSelectContainer>
    </Card>
  );
}
