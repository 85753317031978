import { SiteAction, SiteActionType, SiteReducer, SitePayload, SiteState } from './types';

export const initialState: SiteState = {
  site: undefined,
  cesium: undefined,
  images: [],
  loading: false,
  loadingImages: false,
  error: undefined,
  currentSiteAssessmentId: undefined,
  cesiumLayerSettingsId: undefined,
  userProfile: undefined,
};

const setLoading: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  loading: !!payload?.loading,
});

const setLoadingImages: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  loadingImages: !!payload?.loadingImages,
});

const setUserProfile: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  userProfile: (payload && payload.userProfile) ?? state.userProfile,
});

const setSite: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  site: (payload && payload.site) ?? state.site,
});

const setCurrentImages: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  images: (payload && payload.images) ?? state.images,
});

const setCesiumLayers: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  cesium: (payload && payload.cesium) ?? state.cesium,
});

const setCurrentSiteAssessmentId: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  currentSiteAssessmentId:
    (payload && payload.currentSiteAssessmentId) ?? state.currentSiteAssessmentId,
});
const setCurrentSiteAssessmentCesiumLayerSettingsId: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  cesiumLayerSettingsId:
    (payload && payload.cesiumLayerSettingsId) ?? state.cesiumLayerSettingsId,
});


const setError: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const clearState: SiteReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<SiteActionType, SiteReducer>([
  [SiteActionType.SET_SITE, setSite],
  [SiteActionType.SET_CURRENT_IMAGES, setCurrentImages],
  [SiteActionType.SET_CESIUM_LAYERS, setCesiumLayers],
  [SiteActionType.SET_CURRENT_ASSESSMENT_ID, setCurrentSiteAssessmentId],
  [SiteActionType.SET_CURRENT_ASSESSMENT_CESIUM_LAYER_SETTINGS_ID, setCurrentSiteAssessmentCesiumLayerSettingsId],
  [SiteActionType.SET_LOADING, setLoading],
  [SiteActionType.SET_LOADING_IMAGES, setLoadingImages],
  [SiteActionType.SET_USER_PROFILE, setUserProfile],
  [SiteActionType.SET_ERROR, setError],
  [SiteActionType.CLEAR, clearState],
]);

const reducer = (state: SiteState = initialState, action: SiteAction): SiteState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;