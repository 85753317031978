import styled from "styled-components";

const Container = styled.div`git 
  width: 400px;
  min-height: 80px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
`;

const ButtonsContainer = styled.div`
  padding: 4px;
  display: flex;
`;

export { Container, ButtonsContainer };
