import styled from "styled-components";

const SliderContainer = styled.div`
  transition: all 0.2s ease-in;
  z-index: 10;
`;

const SliderInput = styled.input`
  appearance: none;
  flex: 1;
  align-self: center;
  width: 100%; /* Full-width */
  height: 8px; /* Specified height */
  background: #c8cdd3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 1;
  border-radius: 12px;
  transition: all 0.2s ease-in;

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 14px; /* Set a specific slider handle width */
    height: 14px; /* Slider handle height */
    background: #3367d6; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
    z-index: 50
  }

  &::-webkit-slider-runnable-track {
    z-index: 1
  }

  &::-moz-range-thumb {
    width: 14px; /* Set a specific slider handle width */
    height: 14px; /* Slider handle height */
    background: #243852; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
  }
`;
export { SliderInput, SliderContainer };
