import React from 'react'
import { Math as CesiumMath } from "cesium";
import { MAX_CLIENTHEIGHT, SLIDER_TOP, MIN_HEIGHT, SLIDER_WIDTH  } from "../TilesetInputs/constants";
import { Slider } from "../../../components/Slider";

import { TilesetSliderProps } from './types';
import { Box } from 'ca-react-component-lib'

const TilesetSliders = ({
  windowHeight,
  roll, 
  heading, 
  pitch, 
  debounceNewTilesetEditorParams, 
  tilesetEditorParams}: TilesetSliderProps
  ) => {
  return (
   <Box
   sx={{
    display: windowHeight <= MIN_HEIGHT ? 'none' : 'block',
    position: 'absolute',
    right: '30px',
    bottom: 0,
    top: windowHeight <= MAX_CLIENTHEIGHT ? `${SLIDER_TOP}px` : '',  
    width: `${SLIDER_WIDTH}px`
    }}>        
    <Slider
      max={180}
      min={-180}
      step={0.001}
      style={{transform: 'rotate(90deg) translate(-13em, -14.7em)' }}
      value={roll}
      onValueChange={(value) =>
        debounceNewTilesetEditorParams({
        ...tilesetEditorParams,
           roll: CesiumMath.toRadians(value),
          })
        }
      />
       <Slider
          max={180}
          min={-180}
          step={0.001}
          value={heading}
          onValueChange={(value) =>
            debounceNewTilesetEditorParams({
              ...tilesetEditorParams,
              heading: CesiumMath.toRadians(value),
            })
          }
        />
        <Slider
          max={180}
          min={-180}
          step={0.001}
          style={{transform: 'rotate(45deg) translate(3.5em, -15.7em)', width: '250px',}}
          value={pitch}
          onValueChange={(value) =>
            debounceNewTilesetEditorParams({
              ...tilesetEditorParams,
              pitch: CesiumMath.toRadians(value),
            })
          }
        />
    </Box>
  )
}

export default TilesetSliders