class AuthTokenModel {
  _authToken = "";

  getAuthToken() {
    const SESSION = "consilienceanalytics.session";
    return window.localStorage.getItem(SESSION);
  }
}

export default new AuthTokenModel();
