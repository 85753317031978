import { Cartesian3 } from "cesium";
import { TilesetData, TilesetEditorParams, TilesetSettings } from "./@types";

export const mountTilesetURL = (storage: string, storageFolder: string) => {
  if (storage === "s3" || storage === "azure") {
    return `https://cci-upload.s3.amazonaws.com/b%2Bt_upload/${storageFolder}/tiles_output/tileset.json`;
  }
  return `https://sitewalkdata.s3.amazonaws.com/${storageFolder}/tiles_output/tileset.json`;
};

export const getNewCartesian = (
  latitude: number,
  longitude: number,
  height: number
) => {
  var offset = height;
  return Cartesian3.fromDegrees(longitude, latitude, offset);
};

export const definedNumber = (value: any, defaultValue: number) => {
  if (value === undefined) return defaultValue;
  if (value === null) return defaultValue;
  return value as number;
};

export const compareTilesetSettings = (
  left: TilesetSettings,
  right: TilesetSettings
) => {
  if (left.heading !== right.heading) return true;
  if (left.pitch !== right.pitch) return true;
  if (left.roll !== right.roll) return true;
  if (left.latitude !== right.latitude) return true;
  if (left.longitude !== right.longitude) return true;
  if (left.height !== right.height) return true;
  if (left.scale !== right.scale) return true;
  return false;
};

export const convertTilesetEditorParamsToTilesetData = (
  tilesetEditorParams: TilesetEditorParams
): Partial<TilesetData> => ({
  tilesetX: tilesetEditorParams.offsetX,
  tilesetY: tilesetEditorParams.offsetY,
  tilesetZ: tilesetEditorParams.offsetZ,
  tilesetHeading: tilesetEditorParams.heading,
  tilesetPitch: tilesetEditorParams.pitch,
  tilesetRoll: tilesetEditorParams.roll,
  tilesetHeight: tilesetEditorParams.height,
  tilesetGroundPointHeight: tilesetEditorParams.groundPointHeight,
  tilesetScale: tilesetEditorParams.scale,
  latitude: tilesetEditorParams.latitude,
  longitude: tilesetEditorParams.longitude,
});

export const toCamelCase = (e: string) => {
  return e.includes("i_") ? e.replace("i_", "") : e.replace(/_([a-z])/g, (g) =>  g[1].toUpperCase());
};

export const objectToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(objectToCamelCase);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => ({
      ...acc,
      [toCamelCase(key)]: objectToCamelCase(value)
    }), {});
  }

  return obj;
}

export const toSnakeCase = (e: string | number): string | number => {
  if (!isNaN(+e) || typeof(e) === "number") return "i_"+e;
  return (e.toLowerCase() === e) ? e : e.match(/([A-Z])/g)?.reduce(
    (str: string, c: string) => str.replace(new RegExp(c), '_' + c.toLowerCase()),
    e
  )
    .substring((e.slice(0, 1).match(/([A-Z])/g)) ? 1 : 0) || "";
};

export const objectToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(objectToSnakeCase);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => ({
      ...acc,
      [toSnakeCase(key)]: objectToSnakeCase(value)
    }), {});
  }

  return obj;
}