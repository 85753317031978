import { Card } from "../../../components/Card";
import { ModelCardProps } from "./types";
import { Container, ButtonsContainer } from "./styles";
import { Button } from "ca-react-component-lib";
import { Icon } from "../../../components/Icon";

export function ModelCard({
  model,
  editing,
  onZoomClick,
  onEditClick,
  onFinishEditClick,
  onDeleteClick,
}: ModelCardProps) {
  return (
    <div style={{ marginTop: 10 }}>
      <Card>
        <Container>
          <h4 style={{ margin: 0, padding: "0 0 12px 0" }}>{model.label}</h4>
          <ButtonsContainer>
            <Button
              onClick={() =>
                editing ? onFinishEditClick() : onEditClick(model)
              }
              variant={"outlined"}
              sx={{ mr: 2 }}
            >
              <Icon iconSrc={editing ? "done" : "edit"} />
            </Button>
            <Button
              onClick={() => onDeleteClick(model)}
              variant={"outlined"}
              sx={{ mr: 2 }}
            >
              <Icon iconSrc="delete" />
            </Button>
            <Button
              onClick={() => onZoomClick(model)}
              variant={"outlined"}
              sx={{ mr: 2 }}
            >
              <Icon iconSrc="ads_click" />
            </Button>
          </ButtonsContainer>
        </Container>
      </Card>
    </div>
  );
}
