import { combineReducers } from "redux";
import TilesetDataReducer from "./TilesetData/reducer";
import PlannerDataReducer from "./PlannerData/reducer";
import SiteReducer from './Site/reducer'

const reducers = combineReducers({
  TilesetData: TilesetDataReducer,
  PlannerData: PlannerDataReducer,
  Site: SiteReducer
});

export default reducers;
export type ReducerState = ReturnType<typeof reducers>;
