import { IconProps } from "./types";

export function Icon({ iconSrc, inverted }: IconProps) {
  return (
    <span
      className="material-symbols-outlined"
      style={inverted ? { transform: "rotateZ(180deg)" } : {}}
    >
      {iconSrc}
    </span>
  );
}
