import request from "../request";
import endpoints from "./endpoints";
import { TilesetDataRequestResponse } from "./types";

const fetchTileset = async (assessmentId: string) =>
  request(endpoints.getTileset(assessmentId), {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

const saveTilesetEditorParams = async (
  assessmentId: string,
  tilesetData: { tileset_settings: Partial<TilesetDataRequestResponse> }
) =>
  request(endpoints.updateTileset(assessmentId), {
    method: "PATCH",
    body: JSON.stringify(tilesetData),
    headers: { "Content-Type": "application/json" },
  });

export { fetchTileset, saveTilesetEditorParams };
