import { ModelOptionGroup } from "../../../@types";

export const modelOptions: ModelOptionGroup[] = [
  {
    label: "Prefabricated Groups",
    models: [
      {
        ionId: 476973,
        label: "SuperPro1 with commscope antenna combined",
      },
      {
        ionId: 477033,
        label: "Cabinet on platform combined",
      },
    ],
  },
  {
    label: "Steel - Ground",
    models: [
      {
        ionId: 476354,
        label: "Kenwood T1701KT55S HFrame",
      },
      {
        ionId: 476341,
        label: "Commscope MTC4045LP Platform",
      },
      {
        ionId: 476348,
        label: "Ice Bridge copy 1 - 2' x 16",
      },
    ],
  },
  {
    label: "Steel - Tower",
    models: [
      {
        ionId: 476363,
        label: "SuperPro1 SNP8HR397",
      },
    ],
  },
  {
    label: "Antenna",
    models: [
      {
        ionId: 476353,
        label: "JMA MX08FRO66520 Antenna",
      },
      {
        ionId: 476360,
        label: "primarynberg GPS Glonass36NS",
      },
    ],
  },
  {
    label: "MW Antenna",
    models: [
      {
        ionId: 476388,
        label: "Commscope_VHLP311W_projected_dish",
      },
      {
        ionId: 476387,
        label: "Commscope_VHLP311W",
      },
    ],
  },
  {
    label: "Radio Units",
    models: [
      {
        ionId: 476382,
        label: "Aviat_WTM4000",
      },
      {
        ionId: 476346,
        label: "Fujitsu TA08025B604 RRH",
      },
      {
        ionId: 476385,
        label: "Commscope_RRFA2_Small",
      },
      {
        ionId: 476383,
        label: "Commscope_RRB2BLP_mount",
      },
      {
        ionId: 476356,
        label: "MTI G06RRH4601B RRH",
      },
      {
        ionId: 476357,
        label: "MTI G0607085001B RRH",
      },
    ],
  },
  {
    label: "Electrical",
    models: [
      {
        ionId: 476355,
        label: "Leviton 1N24021 Submeter",
      },
      {
        ionId: 476347,
        label: "GE TLM1220RCUP Breaker",
      },
      {
        ionId: 476362,
        label: "Square D D324NRB Disconnect",
      },
      {
        ionId: 476389,
        label: "Kohler_20REOZK",
      },
      {
        ionId: 476390,
        label: "Kohler 15REOZK",
      },
    ],
  },
  {
    label: "Cabinets",
    models: [
      {
        ionId: 476343,
        label: "Delta XL 30x30x36",
      },
      {
        ionId: 476342,
        label: "CUBE 63912",
      },
      {
        ionId: 476345,
        label: "Enersys 5906",
      },
    ],
  },
  {
    label: "Rooftop Antenna Mounts",
    models: [
      {
        ionId: 476386,
        label: "Commscope RRTFS",
      },
    ],
  },
];
