import React, { useState } from 'react'

// ca
import { FontAwesome, IconButton, InputAdornment, TextField, useTheme } from 'ca-react-component-lib'
import { Math as CesiumMath} from "cesium";

//types
import { TilesetInputProps } from './types';

const TilesetInputs = ({ onValueChange, value, isValid } : TilesetInputProps) => {
    const theme = useTheme();
    const [changeValueInterval, setChangeValueInterval] = useState<ReturnType<typeof setInterval>>();

    const onMouseDown = (step: number) => {
        // set the value in case of a very short click
        if ((Number(value) + step) < -180 || (Number(value) + step) > 180) return;
        onValueChange(CesiumMath.toRadians(Number(value) + step), (Number(value) + step).toFixed(1).toString());

        // update the value every 100ms as long as the mouse is pressed
        let i = 1;
        if (changeValueInterval) clearInterval(changeValueInterval);
        const interval = setInterval(() => {
            const newValue = step * i;
            if ((Number(value) + newValue) < -180 || (Number(value) + newValue) > 180) return;
            onValueChange(CesiumMath.toRadians(Number(value) + newValue), (Number(value) + newValue).toFixed(1).toString());
            i++;
        }, 100);
        setChangeValueInterval(interval);
    }

    const onMouseUp = () => {
        if (changeValueInterval) clearInterval(changeValueInterval);
    }

    const handleInputArrows = (e:any) => {
        if (e.key === "ArrowUp") {
            onMouseDown(0.1);
        } else if (e.key === "ArrowDown") {
            onMouseDown(-0.1);
        }
    }

    return (
    <>
        <IconButton
            sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '20%',
                mr: 1,
                '&:hover': {
                    backgroundColor: theme.palette.primary.darker,
                }
            }}
            onMouseDown={() => onMouseDown(-1)}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
              <FontAwesome icon="backward" type="solid" color="#FFF" />
        </IconButton>

        <IconButton
            sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '20%',
                mr: 1,
                '&:hover': {
                    backgroundColor: theme.palette.primary.darker,
                },
            }}
            onMouseDown={() => onMouseDown(-0.1)}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
            <FontAwesome sx={{transform: 'rotate(180deg)'}} icon="play" type="solid" color="#FFF"  />
        </IconButton>
        <TextField
            sx={{ mr: 1, width: '100px' }}
            error={isValid && isValid(value)}
            autoComplete='off'
            value={value}
            type={'text'}
            InputProps={{
                endAdornment: <InputAdornment position="start">°</InputAdornment>,
            }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if (Number(e.currentTarget.value) < -180 || Number(e.currentTarget.value) > 180) return;
                onValueChange(CesiumMath.toRadians(Number(e.currentTarget.value)), e.currentTarget.value)
            }}
            onKeyDown={handleInputArrows}
            onKeyUp={onMouseUp}
            onBlur={onMouseUp}
        />

        <IconButton
            sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '20%',
                mr: 1,
                '&:hover': {
                    backgroundColor: theme.palette.primary.darker,
                },
            }}
            onMouseDown={() => onMouseDown(0.1)}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
              <FontAwesome  icon="play" type="solid" color="#FFF" />
        </IconButton>

        <IconButton
            sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '20%',
                '&:hover': {
                    backgroundColor: theme.palette.primary.darker,
                },
            }}
            onMouseDown={() => onMouseDown(1)}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
            <FontAwesome icon="forward" type="solid" color="#FFF" />
        </IconButton>
    </>
    )
}

export default TilesetInputs