import { TilesetData, TilesetEditorParams } from "../../@types";
import { TilesetDataAction, TilesetDataActionTypes } from "./types";

const actions = {
  setTilesetData: (tilesetData: TilesetData): TilesetDataAction => ({
    payload: { tilesetData },
    type: TilesetDataActionTypes.SET_TILESET_DATA,
  }),
  fetchTilesetData: (assessmentId: string | null): TilesetDataAction => ({
    payload: { assessmentId },
    type: TilesetDataActionTypes.FETCH_TILESET_DATA,
  }),
  setLoading: (loading: boolean): TilesetDataAction => ({
    payload: { loading },
    type: TilesetDataActionTypes.SET_LOADING,
  }),
  setLoadingSaveNewTilesetEditorParams: (
    savingNewTilesetEditorParams: boolean
  ): TilesetDataAction => ({
    payload: { savingNewTilesetEditorParams },
    type: TilesetDataActionTypes.SET_LOADING_SAVE_NEW_TILESET_EDITOR_PARAMS,
  }),
  setError: (error?: Error): TilesetDataAction => ({
    payload: { error },
    type: TilesetDataActionTypes.SET_ERROR,
  }),
  saveNewTilesetEditorParams: (tilesetEditorParams: TilesetEditorParams) => ({
    payload: { tilesetEditorParams },
    type: TilesetDataActionTypes.SAVE_NEW_TILESET_EDITOR_PARAMS,
  }),
  clearState: (): TilesetDataAction => ({
    type: TilesetDataActionTypes.CLEAR,
  }),
};

export default actions;
