import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 80px;
  right: 20px;
  margin-top: 80px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`;

const PlannerModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;

export { Container, PlannerModelContainer };
