import {
  Cartesian3,
  Color,
  Viewer,
  Entity,
  Property,
  Cartographic,
} from "cesium";

import PositionProperty from "cesium/Source/DataSources/PositionProperty";
import { useCallback, useRef, useState } from "react";
import { CesiumMovementEvent } from "resium";

export const useCesiumFixedCameraView = () => {
  const viewerRef = useRef<Viewer>();

  const cameraEntityRef = useRef<Entity>();
  const [enabledFixedCamera, setEnableFixedCamera] = useState(false);

  const onRightClick = () => {
    if (!cameraEntityRef.current) return;
    if (!viewerRef.current) return;
    if (!cameraEntityRef.current.ellipsoid) return;

    viewerRef.current.selectedEntity = undefined;
    setEnableFixedCamera(false);
  };

  const eastNorthUp = (left: Cartesian3, right: Cartesian3) => {
    var leftCartographic = Cartographic.fromCartesian(left);
    var rightCartographic = Cartographic.fromCartesian(right);
    var leftEast = Cartesian3.fromRadians(
      rightCartographic.longitude,
      leftCartographic.latitude,
      leftCartographic.height
    );
    var rightDown = Cartesian3.fromRadians(
      rightCartographic.longitude,
      rightCartographic.latitude,
      leftCartographic.height
    );
    return new Cartesian3(
      Cartesian3.distance(left, leftEast) *
        (rightCartographic.longitude > leftCartographic.longitude ? 1 : -1),
      Cartesian3.distance(leftEast, rightDown) *
        (rightCartographic.latitude > leftCartographic.latitude ? 1 : -1),
      rightCartographic.height - leftCartographic.height
    );
  };

  const setTrackedEntity = async (
    entity: Entity,
    viewer: Viewer,
    position: Cartesian3
  ) => {
    setEnableFixedCamera(false);
    entity.viewFrom = eastNorthUp(
      position,
      viewer.scene.camera.position
    ) as unknown as Property;
    viewer.selectedEntity = entity;

    setEnableFixedCamera(true);
  };

  const onDoubleClick = (movement: CesiumMovementEvent) => {
    if (!viewerRef.current) return;
    if (!cameraEntityRef.current) return;
    if (!movement.position) return;
    const position = viewerRef.current.scene.pickPosition(movement.position);
    cameraEntityRef.current.position = position as any as PositionProperty;
    cameraEntityRef.current.ellipsoid!.show = true as any as Property;
    if (position) {
      setTrackedEntity(cameraEntityRef.current, viewerRef.current, position);
    }
  };

  const enableFixedPointView = useCallback((viewer: Viewer) => {
    viewerRef.current = viewer;
    cameraEntityRef.current = viewerRef.current.entities.add({
      ellipsoid: {
        show: false,
        radii: new Cartesian3(1, 1, 1),
        material: Color.TRANSPARENT,
      },
    });
  }, []);

  const onClick = () => {
    if (enabledFixedCamera && viewerRef.current && cameraEntityRef.current) {
      viewerRef.current.selectedEntity = cameraEntityRef.current;
    }
  };

  return {
    trackedEntity: enabledFixedCamera ? cameraEntityRef.current : undefined,
    onDoubleClick,
    onRightClick,
    enableFixedPointView,
    onClick,
  };
};
