import { useCallback } from "react";
import { ModelOption, PlannerModel } from "../@types";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import plannerDataSelectors from "../store/PlannerData/selectors";
import plannerDataActions from "../store/PlannerData/actions";

export const useSitePlanner = () => {
  const plannerModels = useSelector(plannerDataSelectors.getPlannerModels);
  const addedPlannerModel = useSelector(
    plannerDataSelectors.getAddedPlannerModel
  );
  const removedPlannerModel = useSelector(
    plannerDataSelectors.getRemovedPlannerModel
  );
  const selectedPlannerModel = useSelector(
    plannerDataSelectors.getSelectedPlannerModel
  );

  const currentAction = useSelector(plannerDataSelectors.getCurrentAction);

  const dispatch = useDispatch();

  const addPlannerModel = useCallback(
    (modelOption: ModelOption) => {
      //@TODO rewrite this!!
      if (modelOption.ionId) {
        const plannerModel: PlannerModel = {
          id: uuid(),
          ionId: modelOption.ionId,
          label: modelOption.label,
        };
        dispatch(plannerDataActions.addPlannerModel(plannerModel));
      } else if (modelOption.shape) {
        const plannerPrimitive: PlannerModel = {
          id: uuid(),
          shape: modelOption.shape,
          label: modelOption.label
        };
        dispatch(plannerDataActions.addPlannerModel(plannerPrimitive));
      }

    },
    [dispatch]
  );

  const removePlannerModel = useCallback(
    (plannerModel: PlannerModel) => {
      dispatch(plannerDataActions.removePlannerModel(plannerModel));
    },
    [dispatch]
  );

  const clearAddedPlannerModel = useCallback(() => {
    dispatch(plannerDataActions.clearAddedPlannerModel());
  }, [dispatch]);

  const clearSelectedPlannerModel = useCallback(() => {
    dispatch(plannerDataActions.clearSelectedPlannerModel());
  }, [dispatch]);

  const zoomToPlannerModel = useCallback(
    (plannerModel: PlannerModel) => {
      dispatch(plannerDataActions.zoomToPlannerModel(plannerModel));
    },
    [dispatch]
  );

  const editPlannerModel = useCallback(
    (plannerModel: PlannerModel) => {
      dispatch(plannerDataActions.editPlannerModel(plannerModel));
    },
    [dispatch]
  );

  return {
    plannerModels,
    addedPlannerModel,
    removedPlannerModel,
    selectedPlannerModel,
    currentAction,
    addPlannerModel,
    clearAddedPlannerModel,
    clearSelectedPlannerModel,
    zoomToPlannerModel,
    editPlannerModel,
    removePlannerModel,
  };
};
