import { Cesium3DTileset, Resource } from "cesium";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TilesetEditorParams, TilesetSettings } from "../@types";
import tilesetDataActions from "../store/TilesetData/actions";
import tilesetDataSelectors from "../store/TilesetData/selectors";
import { definedNumber } from "../utils";

export const useTileset = () => {
  const isLoading = useSelector(tilesetDataSelectors.isLoading);
  const error = useSelector(tilesetDataSelectors.getError);
  const tilesetUrl = useSelector(tilesetDataSelectors.getTilesetUrl);
  const tilesetData = useSelector(tilesetDataSelectors.getTilesetData);
  const tilesetHeight = useSelector(tilesetDataSelectors.getTilesetHeight);
  const tilesetGroundPointHeight = useSelector(tilesetDataSelectors.getTilesetGroundPointHeight);
  const tilesetHeading = useSelector(tilesetDataSelectors.getTilesetHeading);
  const tilesetPitch = useSelector(tilesetDataSelectors.getTilesetPitch);
  const tilesetRoll = useSelector(tilesetDataSelectors.getTilesetRoll);
  const tilesetScale = useSelector(tilesetDataSelectors.getTilesetScale);
  const latitude = useSelector(tilesetDataSelectors.getLatitude);
  const longitude = useSelector(tilesetDataSelectors.getLongitude);
  const siteLatitude = useSelector(tilesetDataSelectors.getOriginLatitude);
  const siteLongitude = useSelector(tilesetDataSelectors.getOriginLongitude);
  const isSavingNewTilesetEditorParams = useSelector(
    tilesetDataSelectors.isSavingNewTilesetEditorParams
  );
  const tilesetOffsetCoordinates = useSelector(
    tilesetDataSelectors.getTilesetOffsetCoordinates
  );
  const tilesetRef = useRef<Cesium3DTileset>();
  
  const [allTilesLoaded, setAllTilesLoaded] = useState(false);

  const dispatch = useDispatch();

  const fetchTilesetData = useCallback(
    (assessmentId: string | null) => {
      dispatch(tilesetDataActions.fetchTilesetData(assessmentId));
    },
    [dispatch]
  );

  const saveNewTilesetEditorParams = useCallback(
    (tilesetEditorParams: TilesetEditorParams) => {
      dispatch(
        tilesetDataActions.saveNewTilesetEditorParams(tilesetEditorParams)
      );
    },
    [dispatch]
  );

  const onAllTilesLoaded = () => {
    setAllTilesLoaded(true);
    if (!tilesetRef.current) return;
    tilesetRef.current.allTilesLoaded.removeEventListener(onAllTilesLoaded);
    tilesetRef.current = undefined;
  };

  const loadTileset = useCallback(async () => {
    if (!tilesetUrl) {
      alert("Tileset url is not set");
      return;
    }
    const replacement = "{replace}";
    const tileset = await Cesium3DTileset.fromUrl(
      new Resource({
        url: tilesetUrl.replace("b%2Bt_upload", replacement),
        templateValues: { replace: "b+t_upload" },
      }),
      { maximumScreenSpaceError: 1 }
    );

    tilesetRef.current = tileset;
    tilesetRef.current.allTilesLoaded.addEventListener(onAllTilesLoaded);

    return tilesetRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tilesetUrl]);

  const initialTilesetSettings: TilesetSettings = useMemo(
    () => ({
      height: definedNumber(tilesetHeight, 90),
      groundPointHeight: definedNumber(tilesetGroundPointHeight, 0),
      heading: definedNumber(tilesetHeading, 0),
      pitch: definedNumber(tilesetPitch, 0),
      roll: definedNumber(tilesetRoll, -1.5707963267949001),
      scale: definedNumber(tilesetScale, 1),
      latitude: definedNumber(latitude, 0),
      longitude: definedNumber(longitude, 0),
      siteLatitude: definedNumber(siteLatitude, 0),
      siteLongitude: definedNumber(siteLongitude, 0),
    }),
    [
      latitude,
      longitude,
      tilesetHeading,
      tilesetHeight,
      tilesetGroundPointHeight,
      tilesetPitch,
      tilesetRoll,
      tilesetScale,
      siteLatitude,
      siteLongitude
    ]
  );

  return {
    isLoading,
    isSavingNewTilesetEditorParams,
    error,
    tilesetData,
    allTilesLoaded,
    initialTilesetSettings,
    tilesetOffsetCoordinates,
    fetchTilesetData,
    loadTileset,
    saveNewTilesetEditorParams,
  };
};
