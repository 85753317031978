export const useLogger = () => {
  const logToDiscord = async (
    level: "ERROR" | "WARNING" | "SUCCESS",
    message: string,
    payload?: any[]
  ) => {
    try {
      const requestURL = `${process.env.REACT_APP_DISCORD_WEBHOOK_URL}/${process.env.REACT_APP_DISCORD_WEBHOOK_ID}/${process.env.REACT_APP_DISCORD_TOKEN}`;
      const env = process.env.NODE_ENV;

      const content = `**[${env.toUpperCase()}]** [${level}] -- ${message} -- at ${new Date()}`;
      const body = JSON.stringify({
        content,
        embeds: payload,
      });
      await fetch(requestURL, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body,
      });
    } catch (e) {
      console.error(e);
    }
  };
  return {
    logToDiscord,
  };
};
