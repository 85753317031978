import {
  Cesium3DTileset,
  HeadingPitchRange,
  TransformEditorViewModel,
  Viewer as CesiumViewer,
  Math as CesiumMath,
  Cartographic,
  Matrix4,
  Cartesian3,
  Transforms,
  HeadingPitchRoll,
} from "cesium";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { TilesetEditorParams, TilesetSettings } from "../@types";
import tilesetDataSelectors from "../store/TilesetData/selectors";
import { getNewCartesian } from "../utils";
import { useTileset } from "./useTileset";

export const useTilesetTransformations = () => {

  const { initialTilesetSettings, tilesetOffsetCoordinates } = useTileset();

  const modelCenterAtOrigin = useSelector(
    tilesetDataSelectors.getModelCenterAtOrigin
  );

  const modelGeoReferenced = useSelector(
    tilesetDataSelectors.getModelGeoReferenced
  );

  const offsetPosition = useSelector(
    tilesetDataSelectors.getTilesetOffsetCoordinates
  );

  const hasTilesetSettings = useSelector(
    tilesetDataSelectors.hasTilesetSettings
  );

  const latitude = useSelector(tilesetDataSelectors.getLatitude);
  const longitude = useSelector(tilesetDataSelectors.getLongitude);
  const height = useSelector(tilesetDataSelectors.getTilesetHeight);

  const zoomToTileset = useCallback(
    (viewer: CesiumViewer, tileset: Cesium3DTileset) => {
      viewer.flyTo(tileset, {
        duration: 2,
        offset: new HeadingPitchRange(
          0.0,
          -0.5,
          tileset.boundingSphere.radius * 2.0
        ),
      });
    },
    []
  );

  const addOnHomeEventListener = useCallback(
    (viewer: CesiumViewer, tileset: Cesium3DTileset) => {
      viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        (e) => {
          e.cancel = true;
          zoomToTileset(viewer, tileset);
        }
      );
    },
    [zoomToTileset]
  );

  const computeTransform = (tilesetSettings: TilesetSettings) => {
    const height = tilesetSettings.height;
    const cartesianOffset = Cartesian3.fromDegrees(
      tilesetSettings.longitude,
      tilesetSettings.latitude,
      height
    );
    const hpr = new HeadingPitchRoll(
      tilesetSettings.heading,
      tilesetSettings.pitch,
      tilesetSettings.roll
    );
    const transform = Transforms.headingPitchRollToFixedFrame(
      cartesianOffset,
      hpr
    );

    Matrix4.multiplyByScale(
      transform,
      new Cartesian3(
        tilesetSettings.scale,
        tilesetSettings.scale,
        tilesetSettings.scale
      ),
      transform
    );
    return transform;
  };

  const resetToGeoRefCoordinates = (
    tileset: Cesium3DTileset
  ): TilesetSettings => {
    const initialTransformPosition = Cartographic.fromCartesian(
      tileset.boundingSphere.center.clone()
    );
    const latitude = CesiumMath.toDegrees(initialTransformPosition.latitude);
    const longitude = CesiumMath.toDegrees(initialTransformPosition.longitude);
    const scale = 1;
    const height = 0;
    const heading = 0;
    const pitch = 0;
    const roll = 0;
    return { latitude, longitude, heading, height, pitch, roll, scale };
  };

  const getOriginCoordinates = (
      tileset: Cesium3DTileset
  ) => {
    const initialTransformPosition = Cartographic.fromCartesian(
        tileset.boundingSphere.center.clone()
    );
    const longitude = CesiumMath.toDegrees(initialTransformPosition.longitude);
    const latitude = CesiumMath.toDegrees(initialTransformPosition.latitude);
    const height = 0;

    return { longitude, latitude, height };
  };

  const initialTransformation = (
    tilesetSettings: TilesetSettings,
    tileset: Cesium3DTileset
  ) => {
    if (hasTilesetSettings) {
      if (typeof height !== "undefined" && height >= 0) {
        tilesetSettings.height = height;
      }
      const cartesianOffsetPosition = Cartographic.fromCartesian(offsetPosition);
      if (cartesianOffsetPosition) {
        tilesetSettings.latitude = CesiumMath.toDegrees(cartesianOffsetPosition.latitude);
        tilesetSettings.longitude = CesiumMath.toDegrees(cartesianOffsetPosition.longitude);
      } else if (typeof latitude !== "undefined" && typeof longitude !== "undefined") {
        tilesetSettings.latitude = latitude;
        tilesetSettings.longitude = longitude;
      }
    }

    tileset.root.transform = Matrix4.IDENTITY;
    if (modelCenterAtOrigin) {
      tileset.modelMatrix = computeTransform(tilesetSettings); // or set tileset._root.transform directly
    }
    if (modelGeoReferenced && !hasTilesetSettings) {
      tilesetSettings = resetToGeoRefCoordinates(tileset);
    }

    return tilesetSettings;
  };

  const transformPosition = useCallback(
    (
      transformEditor: TransformEditorViewModel,
      tileset: Cesium3DTileset,
      tilesetSettings: TilesetSettings
    ) => {
      const newCartesian = getNewCartesian(
        tilesetSettings.latitude,
        tilesetSettings.longitude,
        tilesetSettings.height
      );
      transformEditor.position = newCartesian;
      if (modelCenterAtOrigin) {
        tileset.modelMatrix = computeTransform(tilesetSettings);
      }
      transformEditor.headingPitchRoll = new HeadingPitchRoll(
        tilesetSettings.heading,
        tilesetSettings.pitch,
        tilesetSettings.roll
      );
      transformEditor.scale = new Cartesian3(
        tilesetSettings.scale,
        tilesetSettings.scale,
        tilesetSettings.scale
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modelCenterAtOrigin]
  );
  
  const getCartographicPosition = (offsetPosition: Cartesian3) => {
    return Cartographic.fromCartesian(offsetPosition);
  };


  const getNewTilesetEditorParams = (
    currentTilesetEditorParams: TilesetEditorParams,
    newTransformPosition: Cartesian3
  ): TilesetEditorParams => {
    const cartographicPosition = getCartographicPosition(newTransformPosition);
    return {
      ...currentTilesetEditorParams,
      height: cartographicPosition.height,
      latitude: CesiumMath.toDegrees(cartographicPosition.latitude),
      longitude: CesiumMath.toDegrees(cartographicPosition.longitude),
      offsetX: newTransformPosition.x || 0,
      offsetY: newTransformPosition.y || 0,
      offsetZ: newTransformPosition.z || 0,
    };
  };

  const initialTilesetEditorParams = useMemo(
    () => ({
      ...initialTilesetSettings,
      offsetX: tilesetOffsetCoordinates.x,
      offsetY: tilesetOffsetCoordinates.y,
      offsetZ: tilesetOffsetCoordinates.z,
    }),
    [initialTilesetSettings, tilesetOffsetCoordinates]
  );



  return {
    offsetPosition,
    hasTilesetSettings,
    modelCenterAtOrigin,
    modelGeoReferenced,
    initialTilesetEditorParams,
    getCartographicPosition,
    transformPosition,
    addOnHomeEventListener,
    zoomToTileset,
    initialTransformation,
    getNewTilesetEditorParams,
    getOriginCoordinates
  };
};
