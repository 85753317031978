import { put, call } from 'redux-saga/effects';
import actions from '../actions';
import * as siteServices from '../../../services/site';
import { SiteAction } from '../types';
import { SiteRoot } from '../../../@types';

export function* fetchSite({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId } = payload;

  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    const userProfile = yield call(siteServices.fetchUserProfile);
    yield put(actions.setUserProfile(userProfile));
  } catch (e: any) {
    console.log('Error while fetching user roles');
  }

  try {
    const response: SiteRoot = yield call(siteServices.fetchSite, assessmentId);
  
      yield put(actions.setSite(response));
      const currentAssessment = assessmentId ? response?.siteAssessments?.find(x => x.id === assessmentId) : response?.siteAssessments?.[0];
      const currentSiteAssessmentId = assessmentId || currentAssessment?.id || undefined;

      if (currentSiteAssessmentId) {
        yield put(actions.setCurrentSiteAssessmentId(currentSiteAssessmentId));
        yield put(actions.setCurrentSiteAssessmentCesiumLayerSettingsId(currentAssessment?.cesiumLayerSettingsId));
      }

  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
    alert(`Error while fetching site${e?.message && ": " + e.message}. Reload the page.`);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchPhotos({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }
  const { assessmentId } = payload;

  try {
    yield put(actions.setLoadingImages(true));
    // @ts-ignore
    const images = yield call(siteServices.fetchPhotos, assessmentId);
    const imagesWithCoord = images.filter((x:any) => x.metadata?.lat && x.metadata?.long);
    yield put(actions.setCurrentSiteImages(imagesWithCoord));
  } catch (e: any) {
    console.log(`Error while fetching images${e?.message && ": " + e.message}`);
  } finally {
    yield put(actions.setLoadingImages(false));
  }
}

export function* fetchCesiumLayers({ payload }: SiteAction) {
  if (!payload || !payload.cesiumLayersId) {
    return;
  }

  const { cesiumLayersId } = payload;

  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    const response = yield call(siteServices.fetchCesiumLayers, cesiumLayersId);

    yield put(actions.setCesiumLayers(response));
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
    alert(`Error while fetching site${e?.message && ": " + e.message}. Reload the page.`);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* saveCesiumLayers({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId, layerSettingsId, cesium } = payload;
  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    const response = yield call(siteServices.saveCesiumLayers, assessmentId, layerSettingsId, cesium);
    yield put(actions.setCurrentSiteAssessmentCesiumLayerSettingsId(response.cesium_layer_settings.id));
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
    alert(`Error while saving layers${e?.message && ": " + e.message}.`);
  } finally {
    yield put(actions.setLoading(false));
  }
}