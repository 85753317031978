import styled from "styled-components";

const ModelOptionGroupContainer = styled.div`
  font-weight: 600;
  padding-bottom: 8px;
  display: flex;
  cursor: default;
  user-select: none;
  flex-direction: column;
`;

const ModelOptionContainer = styled.div`
  font-weight: 400;
  padding: 12px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  color: rgb(36, 56, 82);
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: rgba(36, 56, 82, 0.08);
  }
`;
const ListContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 400px;
  padding-top: 10px;
`;
const ListContainer = styled.div`
  height: 0;
  transition: height 0.2s ease-in-out;
  display: flex;
  background-color: white;
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  height: 60px;
  z-index: 2;
`;
const DropDownContainer = styled.div`
  position: absolute;
  padding: 4px;
  background-color: white;
  border-radius: 12px;
  width: 400px;
  border: 1px solid rgba(36, 56, 82, 0.08);
`;

const DropDownButton = styled.div`
  display: grid;
  padding: 12px;
  align-content: center;
  background-color: white;
  cursor: pointer;
  grid-template-columns: 1fr auto;
  span {
    transition: all 0.2s ease-in;
  }
`;

export {
  ModelOptionGroupContainer,
  ModelOptionContainer,
  ListContainer,
  Container,
  DropDownButton,
  DropDownContainer,
  ListContent,
};
