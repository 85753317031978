import { Store } from "redux";

import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import { ApplicationState } from "./types";
import rootSagas from "./rootSagas";
import { configureStore } from "@reduxjs/toolkit";

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export type ApplicationDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSagas);

export default store;
